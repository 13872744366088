<template>
    <div class="grand-total"><p>Prix total : </p><span class="total-amount">{{ formattedTotal }} €</span></div>
</template>

<script>
import { getRoundedPrice } from '@/modules/numbers'

export default {
    props:{
        total:{
            type:[String,Number],
            required:true
        }
    },
    computed: {
        formattedTotal() {
            if (typeof this.total === 'number') {
                return getRoundedPrice(this.total)
            }
            return this.total
        }
    }
}
</script>

<style lang="scss" scoped>
.grand-total {
    margin-right: 10px;
    font-size: 16px;
    line-height: 17px;
    .total-amount 
    {
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
    }
}
</style>