<template>
  <div id="resident-store-thank-you" class="data-list-container">
    <div class="vx-row page-row">
      <div class="vx-col left-col">
        <ResidentHeader
          page-title="Confirmation"
          :displayResidentMenu="false"
          class="mt-sm-0"
        />
        <div class="thankyou-header">
          <div class="thankyou-content">
            <div class="thankyou-title">
              Merci pour votre confiance {{ firstName }} !
            </div>
            <div v-if="draftOnce && draftSubscription" class="thankyou-legend">
              Votre commande <strong>{{ order }}</strong> pour un montant
              de <strong>{{ (totalOnce + totalShippingOnce).toFixed(2) }}€</strong>
              a bien été enregistrée et sera livrée à
              <strong>{{ residentName }}</strong>.<br>
              L'abonnement de {{ residentName }} a également
              été modifié, vous pouvez le consulter à tout moment
              <router-link :to="{name: $Routes.ResidentSubscriptionsCurrent}">dans la rubrique
                "Abonnement" du résident.</router-link>
            </div>
            <div v-if="draftOnce && !draftSubscription" class="thankyou-legend">
              Votre commande <strong>{{order}}</strong> pour un montant
              de <strong>{{ (totalOnce + totalShippingOnce).toFixed(2) }}€</strong>
              a bien été enregistrée et sera livrée à <strong>{{ residentName }}</strong>.<br>
            </div>
            <div v-if="!draftOnce && draftSubscription" class="thankyou-legend">
              L'abonnement de <strong>{{ residentName }}</strong> a bien été modifié,
              vous pouvez le consulter à tout moment
              <router-link :to="{name: $Routes.ResidentSubscriptionsCurrent}">dans la rubrique
                "Abonnement" du résident.</router-link>
            </div>
             <div class="thankyou-legend">
              Vous recevrez par email la confirmation de votre commande de
              produit et services par chacun de nos partenaires. Vous ne
              serez débité(e) que du montant des produits et services acceptés.<br/><br/>
              Si vous avez la moindre question concernant votre commande,
              n'hésitez pas à contacter notre
              <a href="mailto:relation.client@happytal.com" target="_blank">service client</a>.
            </div>
          </div>
        </div>
        <div>
          <div class="actions-item" v-if="draftOnce">
            <vs-button
              class="pcp-btn edit"
              @click="onGoToHistoryBtnClick"
            >Historique des commandes</vs-button>
          </div>
          <div class="actions-item" v-else>
            <vs-button
              class="pcp-btn edit"
              @click="onGoToNextOrderBtnClick"
            >Voir la prochaine commande de l'abonnement
            de {{ residentName }}.</vs-button>
          </div>
        </div>
         <div class="shipping-detail" v-if="draftOnce">
          <div class="detail-header">
            <div class="header-title">Récapitulatif : Commandé une seule fois</div>
            <div class="header-price">
              <GrandTotal
                :total="grandTotal"
                class="grand-total"
              />
              <div class="header-price-delivery">(dont {{totalShippingOnce}}€ de frais de livraison)</div>
            </div>
          </div>

          <div v-if="hasOnceProduct()">
            <div
                v-for="(sales, salesIndex) in getOnceProducts()"
                :key="salesIndex"
                class="order-item"
            >
              <div class="detail-content">
                <div class="order-vendor">
                  <div class="vendor-name">{{ sales.shopName }}</div>
                </div>
                <div class="order-items">
                  <div
                    v-for="(productSale, productSalesIndex) in sales.offers"
                    :key="productSalesIndex"
                    class="order-item"
                  >
                    <div class="item-photo">
                      <img :src="getProductImage(productSale)" class="item-image"/>
                    </div>
                    <div class="item-informations">
                      <div class="item-title">{{ getProductsByOfferId(productSale.offerId) }}</div>
                    </div>
                    <div class="item-quantity">x{{ productSale.lineQuantity }}</div>
                    <div class="item-price">{{ productSale.linePrice }}€</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="hasOnceService()">
            <div
                v-for="(service, serviceIndex) in getOnceServices()"
                :key="serviceIndex"
                class="order-item"
            >
              <div class="detail-content">
                <div class="order-vendor">
                  <div class="vendor-name">{{ service.shop.name }}</div>
                </div>
                <div
                    class="order-items">
                  <div class="order-item">
                    <div class="item-photo">
                      <img :src="getServiceImage(service)" class="item-image"/>
                    </div>
                    <div class="item-informations">
                      <div class="item-title">{{ service.service.model.label }}</div>
                    </div>
                    <div class="item-quantity">x{{ service.originalQuantity }}</div>
                    <div class="item-price">{{ service.price.totalAmount }}€</div>
                  </div>
                  <div class="order-item">
                    <div class="item-informations-delivery">Le prestataire prendra
                      directement contact avec l'EHPAD afin
                      d'organiser le rendez-vous
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!isSubscriptionEmpty()" class="shipping-detail subscription">
          <div class="detail-header">
            <div class="header-title">Récapitulatif : Ajouts d'abonnements</div>
            <div class="header-price">
            </div>
          </div>
          <div v-if="hasSubscriptionProduct()" class="detail-content">
            <div class="order-category">Produits</div>
            <div class="order-items">
              <div
                  v-for="(product, productIndex) in getProducts('subscription', 'product')"
                  :key="productIndex"
                  class="order-item"
              >
                <div class="item-photo">
                  <img :src="product.photo" class="item-image"/>
                </div>
                <div class="item-informations">
                  <div class="item-title">{{ product.title }}</div>
                  <div class="item-description" v-html="product.description"></div>
                  <div class="item-legend" v-html="product.legend"></div>
                </div>
                <div class="item-actions">
                  <div class="item-quantity">x{{ product.quantity }}</div>
                  <div class="item-price">
                    {{ product.price }}€<br/>
                    <div class="item-frequency">{{ formatFrequency(product.frequency) }}</div>
                  </div>
                </div>
              </div>
              <div class="order-item">
                <div class="item-informations-delivery">Première livraison prévue entre
                  le <strong>5 et le 15
                  {{ nextMonth }}</strong><br/>Le montant exact des frais
                  de livraison sera disponible dans votre menu
                  "Abonnement", en fonction des produits qui feront parti
                  de chaque commande mensuelle.
                </div>
              </div>
            </div>
          </div>
          <div v-if="hasSubscriptionService()" class="detail-content">
            <div class="order-category">Services</div>
            <div class="order-items">
              <div
                  v-for="(service, serviceIndex) in getProducts('subscription', 'service')"
                  :key="serviceIndex"
                  class="order-item"
              >
                <div class="item-photo">
                  <img :src="service.photo" class="item-image"/>
                </div>
                <div class="item-informations">
                  <div class="item-title">{{ service.title }}</div>
                  <div class="item-description" v-html="getServiceOptions(service)"></div>
                  <div class="item-legend" v-html="service.legend"></div>
                </div>
                <div class="item-actions">
                  <div class="item-quantity">x{{ service.quantity }}</div>
                  <div class="item-price">
                    {{ service.price }}€<br/>
                    <div class="item-frequency">{{ formatFrequency(service.frequency) }}</div>
                  </div>
                </div>
              </div>
              <div class="order-item">
                <div class="item-informations-delivery"> {{
                    getProducts('subscription', 'service').length > 1 ? 'Le(s) prestataire(s) prendront' : 'Le prestataire prendra'
                  }} directement contact avec l'EHPAD afin d'organiser les rendez-vous.<br/>
                  {{
                    getProducts('subscription', 'service').length > 1 ? 'Les premiers rendez-vous auront' : 'Le premier rendez-vous aura'
                  }}
                  lieu entre le <strong>2 et le {{ nextMonthLastDay }}</strong></div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

import Oauth from '@/oauth'
import {
  computeBasketTotal,
  computeBasketShipping,
  getServiceOptionsAsHtml
} from '@/modules/services'
import { getNextMonth } from '@/modules/date'
import { getFrequencyLabel } from '@/modules/labels'

import Routes from '@/constants/routes'
import Formats from '@/constants/formats'

import ResidentHeader from '@/views/residents/components/ResidentHeader.vue'
import GrandTotal from '@/components/GrandTotal'

export default {
  name: 'StoreThankYou',
  props: [
    'resident_id',
    'order'
  ],
  components: {
    ResidentHeader,
    GrandTotal
  },
  data () {
    return {
      loaded: false,
      draftOnce: null,
      draftSubscription: null,
      firstName: null
    }
  },
  computed: {
    institutionId() {
      return this.$store.getters['dataUser/getInstitutionId']()
    },
    userId() {
      return this.$store.getters['dataUser/getUserId']()
    },
    residentName() {
      return this.$store.getters['dataResidents/getResidentName'](this.resident_id)
    },
    currentBasket() {
      return this.$store.getters['dataMultiBasket/getBasketByResidentId'](this.resident_id)
    },
    totalOnce() {
      return computeBasketTotal(this.currentBasket)
    },
    totalShippingOnce() {
      return computeBasketShipping(this.currentBasket)
    },
    grandTotal() {
      return this.totalOnce + this.totalShippingOnce
    },
    nextMonth() {
      return getNextMonth()
    },
    nextMonthLastDay() {
      moment.locale('fr')
      return moment().add(1, 'M').endOf('month').format(Formats.DisplayDateText)
    }
  },
  methods: {
    getResidentById (residentId) {
      return this.$store.getters['dataResidents/getResidentById'](residentId)
    },
    getServiceOptions(service) {
      return getServiceOptionsAsHtml(service)
    },
    getOnceProducts() {
      return _.get(this.currentBasket, 'quoteContent.quoteContentProduct.orders', null)
    },
    hasOnceProduct() {
      return this.getOnceProducts() != null
    },
    getOnceServices() {
      return _.get(this.currentBasket, 'quoteContent.quoteContentService.orders', null)
    },
    hasOnceService() {
      return this.getOnceServices() != null
    },
    isSubscriptionEmpty() {
      if (!this.draftSubscription) {
        return true
      }
      if (!_.get(this.draftSubscription, 'products.length', null)) {
        return true
      }
      return false
    },
    hasSubscriptionProduct() {
      return this.getProducts('subscription', 'product').length > 0
    },
    hasSubscriptionService() {
      return this.getProducts('subscription', 'service').length > 0
    },
    formatFrequency(frequency) {
      return getFrequencyLabel(frequency)
    },
    getProducts(draftType, productType) {
      let products = []
      if (draftType == 'once') {
        products = _.get(this.draftOnce, 'products', [])
        return products.filter(p => p.type == productType)
      }
      else {
        products = _.get(this.draftSubscription, 'products', [])
        return products.filter(p => p.type == productType)
      }
    },
    getProductImage(productSale) {
      if (!this.draftOnce) {
        return null
      }
      const product = this.draftOnce.products.find(p => p.type === 'product' && p.meta.offer.offerId === productSale.offerId)
      return _.get(product, 'photo', null)
    },
    getServiceImage(service) {
      if (!this.draftOnce) {
        return null
      }
      const product = this.draftOnce.products.find(p => p.type === 'service' && p.meta.service.code === service.service.code)
      return _.get(product, 'meta.serviceModel.publicData.imageUrl', null)
    },
    getProductsByOfferId(offerId) {
      const lineProducts = _.get(this.currentBasket, 'basketLineProducts', [])
      const product = _.find(lineProducts, (product) => {
        if (product.offerId == offerId) {
          return product
        }
      })
      return product.label
    },
    // BUTTON ACTIONS
    onGoToNextOrderBtnClick(e) {
      this.$router.push({
        name: Routes.ResidentSubscriptionsNext,
        params: {
          resident_id: this.resident_id
        }
      })
    },
    onGoToHistoryBtnClick(e) {
      this.$router.push({
        name: Routes.ResidentHistory,
        params: {
          resident_id: this.resident_id
        }
      })
    },
  },
  async mounted() {
    this.draftOnce = this.$store.getters['dataDrafts/getFirstDraft'](this.userId, this.resident_id, 'once')
    if (_.get(this.draftOnce, 'products.length', 0) < 1) {
      this.draftOnce = null
    }
    this.draftSubscription = this.$store.getters['dataDrafts/getFirstDraft'](this.userId, this.resident_id, 'subscription')
    this.firstName = _.get(this.$store, 'state.dataUser.user.firstName', '-')

    if (this.$gtm.enabled() && this.draftOnce != null) {
      const orderOnceProducts = this.getProducts('once', 'product')
      const orderOnceServices = this.getProducts('once', 'service')
      const itemsToPush = []
      if (orderOnceProducts) {
        for (const orderOnceProducts of orderOnceProducts)   {
          itemsToPush.push({
            item_id: orderOnceProducts.id,
            item_name: orderOnceProducts.title,
            price: orderOnceProducts.price,
            item_category: "",
            item_category_2: "",
            quantity: orderOnceProducts.quantity
          })
        }
      }
      if (orderOnceServices) {
        for (const orderOnceService of orderOnceServices) {
          itemsToPush.push({
            item_id: orderOnceService.id,
            item_name: orderOnceService.title,
            price: orderOnceService.price,
            item_category: "",
            item_category_2: "",
            quantity: orderOnceService.quantity
          })
        }
      }
      if (itemsToPush.length > 0 ) {
        this.$gtm.trackEvent({
            event: 'purchase',
            value: this.totalOnce,
            shipping: this.totalShippingOnce,
            currency: 'EUR',
            transaction_id: this.order,
            ecommerce: {
              items: itemsToPush
            },
            buyMode:'once'
        })
      }
    }
    Oauth.checkUserRight(this.getResidentById(this.resident_id), this.$router)
    this.$vs.loading.close()
    this.$store.commit('dataDrafts/DELETE_USER_DRAFTS', {
      userId: this.userId
    })
    this.$store.commit('dataDrafts/SAVE_DRAFTS')
    if (this.currentBasket) {
      this.currentBasket.resident_id = this.resident_id
      this.$store.commit('dataMultiBasket/DELETE_MULTI_BASKET', {
        basket: this.currentBasket
      })
    }

    // Refresh residents to update promotional eligibility
    await this.$store.dispatch('dataResidents/getResidents', {
      userId: this.userId,
      institutionId: this.institutionId
    })
  }
}
</script>

<style lang="scss">
@import '~@happytal/bo-ui-library/src/styles/variables';

#resident-store-thank-you {
  .page-row {
    flex-wrap: nowrap;
  }

  .left-col {
    flex-grow: 1;
  }

  .right-col {
    flex-shrink: 0;
    flex-basis: 450px;
  }

  .back-section {
    display: inline-flex;
    align-items: center;
    margin: 0px 0px 14px 0px;
    font-family: Montserrat;
    cursor: pointer;

    .back-btn {

      .vs-icon {
        font-size: 30px;
      }
    }

    .back-legend {
      margin: 0px 0px 0px 13px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 29px;
      color: rgba(var(--vs-primary), 1);
      user-select: none;
    }
  }

  .thankyou-header {
    margin: 0px 0px 22px 0px;

    .thankyou-content {

      @media (max-width: map-get($breakpoints, sm)) {
        margin: 0px 15px 0px 15px;
      }

      .thankyou-title {
        font-weight: 600;
        color: #2C2C2C;
      }
      .thankyou-legend {
        margin: 15px 0px 0px 0px;
      }
    }
  }
  .actions-item {
    display: flex;
    justify-content: center;
  }

  .shipping-detail {
    margin: 40px 0px 72px 0px;
    border: 2px solid #D8D8D8;
    border-radius: 8px;
    color: #2C2C2C;

    .detail-header {
      display: flex;
      align-items: center;
      padding: 22px 22px 22px 22px;

      .header-title {
        flex-grow: 1;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;

        @media (min-width: map-get($breakpoints, sm)) {
          font-size: 18px;
        }
        @media (max-width: map-get($breakpoints, sm)) {
          font-size: 16px;
        }
      }

      .header-price {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        text-align: right;

        .header-price-delivery {
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          text-align: right;
        }
        .next-order-amount {
          font-weight: normal;
          font-size: 12px;
        }
      }
    }

    .detail-content:last-child {
      margin: 10px 0px 0px 0px;
    }

    .detail-content {
      margin: 0px 0px 37px 0px;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.135216);
      border-radius: 8px;
      background: white;

      .order-category {
        padding: 10px 22px 10px 22px;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
      }

      .order-items {

        .order-item {
          display: flex;
          padding: 22px 22px 22px 22px;
          border-top: 1px solid #D8D8D8;

          .item-informations-delivery {
            text-align: center;
            margin: 5px auto;
          }

          .item-photo {

            .item-image {
              width: 92px;
              height: auto;
            }
          }

          .item-informations {
            flex-grow: 1;
            margin: 0px 0px 0px 18px;

            .item-title {
              margin: 0px 10px 0px 0px;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 18px;
            }

            .item-description {
              margin: 4px 0px 0px 0px;
              font-style: normal;
              font-weight: 300;
              font-size: 14px;
              line-height: 14px;
            }

            .item-legend {
              margin: 4px 0px 0px 0px;
              font-style: normal;
              font-weight: 300;
              font-size: 14px;
              line-height: 14px;
            }

            .item-frequency {
              margin: 8px 0px 0px 0px;
              font-weight: bold;
              font-size: 14px;
              line-height: 14px;
            }

            .frequency-select {
              width: 202px;
              margin: 9px 0px 0px 0px;
            }
          }

          .item-actions {
            display: flex;
            align-items: center;

            @media (max-width: map-get($breakpoints, sm)) {
              flex-wrap: wrap;
            }

            .quantity-select {
              width: 200px;
              margin: 0px 0px 30px 0px;
              border: 1px solid rgba(var(--vs-primary), 1);
              border-radius: 5px;
            }

            .item-price {
              font-style: normal;
              font-weight: 600;
              font-size: 18px;
              line-height: 14px;
              text-align: right;
            }

            .item-frequency {
              margin: 8px 0px 0px 0px;
              font-weight: bold;
              font-size: 14px;
              line-height: 14px;
            }
          }
        }
      }

      .order-vendor {
        display: flex;
        align-items: center;
        padding: 10px 22px 10px 22px;

        .vendor-name {
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 22px;
        }

        .vendor-informations {
          margin: 0px 0px 0px 10px;

          a {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
            text-decoration: underline;
            color: rgba(var(--vs-primary), 1);
          }
        }
      }

      .order-items {
        .order-item {
          border-top: none;
          display: flex;
          align-items: center;
          padding: 9px 22px 9px 22px;
          border-bottom: 1px solid #D8D8D8;

          .item-title {
            flex-grow: 1;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
          }

          .item-quantity {
            padding: 4px 20px 4px 20px;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 17px;
            background: #BDBDBD;
            border-radius: 3px;
            text-align: center;
            color: white;
          }

          .item-price {
            width: 120px;
            margin: 0px 0px 0px 20px;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 14px;
            text-align: right;
          }
        }
      }

      .shipping-label {
        margin: 9px 0px 0px 0px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
      }

      .shipping-selector {

      }

      .shipping-custom {
        padding: 10px 0px 10px 0px;
        border-bottom: 1px solid #D8D8D8;

        .label {
          max-width: 100%;
          margin: 0px auto 0px auto;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 18px;
          text-align: center;
        }
      }

      .shipping-fees {
        display: flex;
        align-items: center;
        padding: 22px 22px 22px 22px;

        .fees-title {
          flex-grow: 1;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 22px;
        }

        .fees-price {
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 14px;
          text-align: right;
        }
      }
    }
  }

  .shipping-summary {
    position: fixed;
    max-width: 314px;
    padding: 22px 22px 22px 22px;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.135216);
    border-radius: 8px;
    font-family: Montserrat;
    color: #2C2C2C;
  }
}
</style>
